import { useRef, useEffect, useState, useCallback } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback = (...args: any[]) => void;

export function useInterval(callback: Callback, delay: number | null): void {
  const savedCallback = useRef<Callback | undefined>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick(): void {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
}

export function useTimeout(callback: Callback, delay: number | null): void {
  const savedCallback = useRef<Callback | undefined>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick(): void {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return (): void => clearTimeout(id);
    }
  }, [delay]);
}

export function useCountdown(delay: number, start: boolean) {
  const [secondsRemaining, setSecondsRemaining] = useState(0);
  const isTimeRemaining = secondsRemaining > 0;

  useEffect(() => {
    if (start) {
      setSecondsRemaining(delay);
    }
  }, [start, delay]);

  useInterval(
    () => {
      setSecondsRemaining((prevValue) => Math.max(0, prevValue - 1));
    },
    isTimeRemaining ? 1000 : null
  );

  const restart = useCallback(() => setSecondsRemaining(delay), [delay]);

  return {
    secondsRemaining,
    isTimeRemaining,
    restart,
  };
}
