import { JSX } from "react";
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";

export const defaultQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
};

/*
 * Important note about QueryClient: it's responsible for maintaining
 * react-query's internal state (like caching), and is simply provided to a
 * QueryClientProvider instance. So if the QueryClientProvider is unmounted and
 * remounted (say, if each route wraps its component tree under its own
 * provider), it'll still have access to the cache as long as the original
 * QueryClient is preserved.
 *
 * That leads to a nuance here: the queryClient generated before is at module
 * scope, so it'll stay in memory even if the provider unmounts. If it were
 * moved to the body of the provider though, a new client would be generated
 * each time the provider is re-rendered (as well as re-mounted).
 *
 * Ex: This would generate a new client every render, losing its cache:
 *
 * ```
 * <ReactQueryClientProvider client={queryClient}>
 *   {children}
 * </ReactQueryClientProvider>
 * ```
 *
 * Just important to keep in mind, there are implications to not doing the
 * instantiation like below.
 */
const defaultQueryClient = new QueryClient(defaultQueryClientConfig);

type QueryClientProviderProps = {
  queryClient?: QueryClient;
  children: React.ReactNode;
};

export function QueryClientProvider({
  queryClient,
  children,
}: QueryClientProviderProps): JSX.Element {
  return (
    <ReactQueryClientProvider client={queryClient || defaultQueryClient}>
      {children}
    </ReactQueryClientProvider>
  );
}
