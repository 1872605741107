import { ComponentType } from "react";
import cx from "classnames";
import { LoadingSpinnerIcon } from "#app-component-lib/icons/animated/LoadingSpinnerIcon";
import { IconProps } from "#app-component-lib/icons/types";

export const ButtonType = ["primary", "secondary", "tertiary"] as const;
export const ButtonStatus = ["default", "negative"] as const;

export type ButtonPropsBase = {
  type: (typeof ButtonType)[number];
  text: string;
  status?: (typeof ButtonStatus)[number];
  iconOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
  tooltip?: string;
  iconPosition?: "left" | "right";
  block?: boolean;
  Icon?: ComponentType<IconProps>;
};

export function ButtonContent({
  text,
  loading,
  iconOnly,
  iconPosition = "left",
  Icon,
}: ButtonPropsBase) {
  const LoadingSpinnerIconContent = loading ? (
    <LoadingSpinnerIcon
      size="md"
      interactive
      noFill
      decorative={!iconOnly}
      linkOrButton={iconOnly}
    />
  ) : null;

  const IconContent =
    Icon && !(iconOnly && loading) ? (
      <Icon
        size="md"
        interactive
        noFill
        decorative={!iconOnly}
        linkOrButton={iconOnly}
      />
    ) : null;

  const TextContent = !iconOnly ? (
    <span className="truncate">{text}</span>
  ) : null;

  if (iconPosition === "right") {
    return (
      <>
        {LoadingSpinnerIconContent}
        {TextContent}
        {IconContent}
      </>
    );
  }

  return (
    <>
      {IconContent}
      {TextContent}
      {LoadingSpinnerIconContent}
    </>
  );
}

type Segment = "start" | "middle" | "end";

export function buildButtonClassNames({
  type,
  loading,
  disabled,
  status,
  block,
  segment,
}: Partial<ButtonPropsBase> & { segment?: Segment }) {
  return cx(buildBaseClassNames({ loading, disabled, block }), {
    [buildPrimaryClassNames(status, segment)]: type === "primary",
    [buildSecondaryClassNames(segment)]: type === "secondary",
    [buildTertiaryClassNames(status)]: type === "tertiary",
  });
}

function buildBaseClassNames({
  loading,
  disabled,
  block,
}: Partial<ButtonPropsBase>) {
  return cx(
    "flex items-center justify-center",
    "text-label-md",
    // For truncate to work https://stackoverflow.com/a/72968312
    "max-w-full",
    "disabled:fill-int-icon-disabled disabled:text-int-txt-disabled",
    {
      "w-full": block,
      "cursor-not-allowed": disabled,
      "cursor-progress": loading,
    }
  );
}

function buildBasePrimaryOrSecondaryClassNames(segment?: Segment) {
  return cx(
    "px-3 py-1 h-11 gap-x-2",
    "disabled:bg-int-bg-disabled disabled:border-none",
    {
      "rounded-sm rounded-r-none": segment === "start",
      "rounded-sm rounded-l-none": segment === "end",
      "rounded-sm": !segment,
    }
  );
}

function buildPrimaryClassNames(
  status: ButtonPropsBase["status"],
  segment?: Segment
) {
  const base = buildBasePrimaryOrSecondaryClassNames(segment);

  return cx(base, "text-int-txt-inverse fill-int-icon-inverse", {
    [cx(
      "bg-int-bg-primary hover:bg-int-bg-primary-hover active:bg-int-bg-primary-active",
      "focus-visible:shadow-spread focus-visible:shadow-int-brand outline-none"
    )]: status === "default",
    [cx(
      "bg-int-bg-negative hover:bg-int-bg-negative-hover active:bg-int-bg-negative-active",
      "focus-visible:shadow-spread focus-visible:shadow-int-negative outline-none"
    )]: status === "negative",
  });
}

function buildSecondaryClassNames(segment?: Segment) {
  const base = buildBasePrimaryOrSecondaryClassNames(segment);

  return cx(
    base,
    "bg-int-bg-secondary text-int-txt-brand fill-int-icon-brand",
    "border-2 border-border-brand hover:bg-int-bg-secondary-hover active:bg-int-bg-secondary-active",
    "focus-visible:shadow-spread focus-visible:shadow-int-brand outline-none",
    {
      "border-r-0": segment === "start" || segment === "middle",
    }
  );
}

function buildTertiaryClassNames(status: ButtonPropsBase["status"]) {
  return cx("gap-x-1", {
    [cx(
      "text-int-txt-brand hover:text-int-txt-brand-hover active:text-int-txt-brand-active",
      "fill-int-icon-brand hover:fill-int-icon-brand-hover active:fill-int-icon-brand-active"
    )]: status === "default",
    [cx(
      "text-int-txt-negative hover:text-int-txt-negative-hover active:text-int-txt-negative-active",
      "fill-int-icon-negative hover:fill-int-icon-negative-hover active:fill-int-icon-negative-active"
    )]: status === "negative",
  });
}
