import cx from "classnames";

export type CardProps = {
  children: React.ReactNode;
  /*
   * Use case: Expanding the card to fill a parent, eg for a full-page card.
   * Necessary because the Card ultimately wraps its children in its own div,
   * which means the children wouldn't be able to fill the parent without this
   * prop set.
   * Note: This sets both the hight as well as flex properties. The height does
   * work on its own, but even with border box sizing, it was observed an
   * `h-full` child overflowed the Card without it being flex. Not sure why but
   * surely some CSS nuance.
   */
  fullHeight?: boolean;
  roundedClassName?: string;
  shadowClassName?: string;
};

export function Card({
  children,
  fullHeight,
  roundedClassName = "rounded-sm",
  shadowClassName = "shadow-sm",
}: CardProps) {
  return (
    <div
      className={cx(roundedClassName, shadowClassName, "bg-bg-container", {
        "flex h-full min-h-[800px] flex-1 flex-col": fullHeight,
      })}
      role="region"
    >
      {children}
    </div>
  );
}
