import { Renderable, toast, Toaster, ValueOrFunction } from "react-hot-toast";
import cx from "classnames";
import { ErrorFilledIcon } from "#app-component-lib/icons/filled/ErrorFilledIcon";
import { InfoFilledIcon } from "#app-component-lib/icons/filled/InfoFilledIcon";
import { SuccessFilledIcon } from "#app-component-lib/icons/filled/SuccessFilledIcon";
import { WarningFilledIcon } from "#app-component-lib/icons/filled/WarningFilledIcon";
import "./styles.css";

export const TOAST_DURATION = 4000;

export const ToastStatus = ["info", "warning", "positive", "negative"] as const;

type ToastPropsBase = {
  status: (typeof ToastStatus)[number];
  visible: boolean;
};

type ToastPropsContent =
  | {
      contentType: "text";
      text: string;
    }
  | {
      contentType: "component";
      component: React.ReactNode;
    };

type ToastProps = ToastPropsBase & ToastPropsContent;

function Toast({ status, visible, ...props }: ToastProps) {
  return (
    <div
      className={cx({
        "animate-enter": visible,
        "animate-leave": !visible,
      })}
    >
      <div className="bg-bg-container flex items-center gap-x-2 rounded-sm p-4 shadow-lg sm:w-96">
        <div className="self-start">
          {status === "info" && <InfoFilledIcon size="md" />}
          {status === "warning" && <WarningFilledIcon size="md" />}
          {status === "positive" && <SuccessFilledIcon size="md" />}
          {status === "negative" && <ErrorFilledIcon size="md" />}
        </div>
        <div className="text-txt-body text-body-sm flex-1">
          {props.contentType === "text" && <p>{props.text}</p>}
          {props.contentType === "component" && props.component}
        </div>
      </div>
    </div>
  );
}

export type FireToastParams = Pick<ToastPropsBase, "status"> &
  ToastPropsContent;

export function fireToast(params: FireToastParams) {
  toast.custom((t: ValueOrFunction<Renderable, unknown>) => (
    <Toast visible={t.visible} {...params} />
  ));
}

export function ToastRoot() {
  return (
    // There doesn't appear to be a way to change the role depending on the type
    // of toast - ie status for info/success, alert for warning/negative. And we
    // can't just duplicate them into separate Toaster's because that would
    // introduce a second queue for toasts which could lead to weird overlapping
    // behavior (untested). But according to general guidance, the alert role
    // shouldn't usually be used anyway, even for operational errors:
    // https://stackoverflow.com/a/75526068
    <div role="status">
      <Toaster toastOptions={{ duration: TOAST_DURATION }} />
    </div>
  );
}
