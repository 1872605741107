import { ComponentType } from "react";
import cx from "classnames";
import { IconProps } from "#app-component-lib/icons/types";

export const IconFrameColor = [
  "default",
  "info",
  "positive",
  "negative",
  "warning",
  "brand",
] as const;
export const IconFrameVariant = ["light", "dark"];

export type IconFrameProps = {
  Icon: ComponentType<IconProps>;
  color?: (typeof IconFrameColor)[number];
  variant?: (typeof IconFrameVariant)[number];
};

export function IconFrame({
  color = "default",
  variant = "light",
  Icon,
}: IconFrameProps) {
  return (
    <div
      className={cx(
        "flex size-14 shrink-0 items-center justify-center rounded-full",
        {
          [cx({
            "bg-bg-main": color === "default" || color === "brand",
            "bg-bg-info-light": color === "info",
            "bg-bg-positive-light": color === "positive",
            "bg-bg-negative-light": color === "negative",
            "bg-bg-warning-light": color === "warning",
          })]: variant === "light",
        },
        {
          [cx({
            "bg-bg-neutral-dark": color === "default",
            "bg-bg-info": color === "info",
            "bg-bg-positive": color === "positive",
            "bg-bg-negative": color === "negative",
            "bg-bg-warning": color === "warning",
            "bg-bg-container-accent": color === "brand",
          })]: variant === "dark",
        }
      )}
    >
      <Icon color={variant === "light" ? color : "inverse"} size="md" />
    </div>
  );
}
