import { JSX } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Card,
  CardBody,
  RenderStateSection,
  RenderStateSectionProps,
  Button,
} from "@pl/app-component-lib";
import { logError, logWarn } from "@pl/app-services";

type FullPageNoticeBaseProps = {
  variant: RenderStateSectionProps["variant"];
  titleText: JSX.Element;
  bodyText: JSX.Element;
  noticeContext?: string;
  ctaText?: string;
  cta?: () => void;
};

function FullPageNotice({
  variant,
  titleText,
  bodyText,
  noticeContext,
  ctaText,
  cta,
}: FullPageNoticeBaseProps) {
  if (noticeContext) {
    if (variant === "error") {
      logError({ message: "FullPageNotice error", data: { noticeContext } });
    }
    if (variant === "warning") {
      logWarn({ message: "FullPageNotice warning", data: { noticeContext } });
    }
  }

  return (
    <div className="flex-1">
      <Card>
        <CardBody>
          <RenderStateSection
            variant={variant}
            height="lg"
            title={<h2 className="text-heading-sm">{titleText}</h2>}
            body={<p>{bodyText}</p>}
            cta={
              cta && ctaText ? (
                <Button type="primary" text={ctaText} onClick={cta} />
              ) : undefined
            }
          />
        </CardBody>
      </Card>
    </div>
  );
}

export type FullPageErrorProps = {
  errorContext?: string;
  titleText?: JSX.Element;
  bodyText?: JSX.Element;
  ctaText?: string;
  cta?: () => void;
  noCta?: boolean;
};

export function FullPageError({
  errorContext,
  titleText,
  bodyText,
  ctaText,
  cta,
  noCta,
}: FullPageErrorProps) {
  const { formatMessage } = useIntl();

  let noticeCta = undefined;

  if (cta && !noCta) {
    noticeCta = cta;
  } else if (!noCta) {
    noticeCta = () => window.location.reload();
  }

  return (
    <FullPageNotice
      variant="error"
      noticeContext={errorContext}
      titleText={
        titleText || (
          <FormattedMessage id="global.error.something-went-wrong.title" />
        )
      }
      bodyText={
        bodyText || (
          <FormattedMessage
            id="global.error.something-went-wrong.body"
            values={{
              bold: (chunk) => (
                <span className="font-bold" key={chunk[0]?.toString()}>
                  {chunk}
                </span>
              ),
            }}
          />
        )
      }
      ctaText={ctaText || formatMessage({ id: "global.action.reload" })}
      cta={noticeCta}
    />
  );
}

export function FullPageErrorNoI18n({
  errorContext,
  titleText,
  bodyText,
  ctaText,
  cta,
}: FullPageErrorProps) {
  return (
    <FullPageNotice
      variant="error"
      noticeContext={errorContext}
      titleText={titleText || <>Something went wrong</>}
      bodyText={
        bodyText || (
          <>
            An unexpected error has occurred. Please reload the page by
            selecting <span className="font-bold">Reload</span> below.
          </>
        )
      }
      ctaText={ctaText || "Reload"}
      cta={cta ? cta : () => window.location.reload()}
    />
  );
}

export type FullPageWarningProps = {
  titleText: JSX.Element;
  bodyText: JSX.Element;
  warningContext?: string;
  ctaText?: string;
  cta?: () => void;
};

export function FullPageWarning({
  titleText,
  bodyText,
  warningContext,
  ctaText,
  cta,
}: FullPageWarningProps) {
  return (
    <FullPageNotice
      variant="warning"
      noticeContext={warningContext}
      titleText={titleText}
      bodyText={bodyText}
      ctaText={ctaText}
      cta={cta}
    />
  );
}
